import React, {useState} from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Container,
  Box,
  Collapse,
  Flex,
  Heading,
  Text,
  LinkOverlay,
  LinkBox,
  useColorMode
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import styled from '@emotion/styled'

const ImageBox = styled.div`
    transition: 0.5s ease;
    backface-visibility: hidden;
    position: relative; 
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    &:hover {
        opacity: 0.5;
    }
`;

const Portfolio = ({ data }) => {
    
    const { colorMode } = useColorMode()

  const document = data.allPrismicPortfolioGallery.edges;
    
  return (
    <Layout>
      <Seo title="Willowcreek Design Group | Portfolio" />
      <Container maxWidth="container.xl">
        <Box my="12">
            <Heading as="h1" fontSize="2.25rem" textTransform="uppercase" fontWeight="100" textAlign="center" mb={4}>
                Willow Creek Design Group Portfolio
            </Heading>
        </Box>
        <Box my="12">
            <Flex alignItems="center" flexWrap="wrap">
                
                {document.map((item, index) => (
                <LinkBox w={{base: '100%', md: '33%', lg: '25%'}} p={2}>
                    <LinkOverlay as={GatsbyLink} to={`/portfolio/${item.node.uid}`}>
                <ImageBox position="relative">
                    <GatsbyImage key={index} image={getImage(item.node.data.featured_image.localFile)} alt={item.node.data.featured_image.alt} />
                    <Box position="absolute" w="100%" zIndex="10" bottom="0" bg="rgba(255, 255, 255, 0.8)" p="4" h="30%">
                      <Flex h="100%" flexDirection="column" justifyContent="center">
                      <Text fontSize="1.25rem" fontWeight="700" textTransform="uppercase" color={colorMode === "light" ? 'river-bed.500' : '#FFFFFF'} >
                            {item.node.data.portfolio_title.text}
                        </Text>
                      </Flex>
                    </Box>
                </ImageBox>
                </LinkOverlay>
                </LinkBox>
            ))}
            </Flex>
        </Box>
    </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicPortfolioGallery(sort: {order: DESC, fields: last_publication_date}) {
      edges {
        node {
            uid
          data {
            portfolio_title {
              text
            }
            featured_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    aspectRatio: 1
                    transformOptions: {cropFocus: CENTER}
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Portfolio;
